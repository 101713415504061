/* src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Inter Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

/* Global Styles */
body {
    background-color: var(--background-color);
    color: var(--text-color);
    min-height: 100vh;
    font-family: 'Inter', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Optional: Custom Scrollbar Styles */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

/* Theme Definitions */

:root {
    /* Light Theme (Default) */
    --background-color: #f9fafb; /* Light gray */
    --text-color: #1f2937; /* Dark gray */
    --modal-background: #ffffff; /* White */
    --sidebar-background: #ffffff; /* White */
    --button-background-color: #3b82f6; /* Blue */
    --placeholder-color: #6b7280; /* Gray-500 */
}

[data-theme="Dark"] {
    --background-color: #1f2937; /* Dark gray */
    --text-color: #3e66e7; /* Light gray for better contrast */
    --modal-background: #374151; /* Slightly lighter dark gray */
    --sidebar-background: #111827; /* Very dark gray */
    --button-background-color: #2563eb; /* Darker blue */
    --placeholder-color: #d1d5db; /* Gray-300 */
}

[data-theme="Blue"] {
    --background-color: #e0f2fe; /* Light blue */
    --text-color: #1e40af; /* Dark blue */
    --modal-background: #bae6fd; /* Medium light blue */
    --sidebar-background: #93c5fd; /* Medium blue */
    --button-background-color: #2563eb; /* Blue */
    --placeholder-color: #1e40af; /* Dark blue */
}

[data-theme="Green"] {
    --background-color: #ecfdf5; /* Light green */
    --text-color: #065f46; /* Dark green */
    --modal-background: #d1fae5; /* Medium light green */
    --sidebar-background: #a7f3d0; /* Medium green */
    --button-background-color: #10b981; /* Green */
    --placeholder-color: #065f46; /* Dark green */
}

[data-theme="Red"] {
    --background-color: #fee2e2; /* Light red */
    --text-color: #991b1b; /* Dark red */
    --modal-background: #fecaca; /* Medium light red */
    --sidebar-background: #fca5a5; /* Medium red */
    --button-background-color: #ef4444; /* Red */
    --placeholder-color: #991b1b; /* Dark red */
}

[data-theme="Purple"] {
    --background-color: #f3e8ff; /* Light purple */
    --text-color: #6b21a8; /* Dark purple */
    --modal-background: #e9d5ff; /* Medium light purple */
    --sidebar-background: #d8b4fe; /* Medium purple */
    --button-background-color: #a855f7; /* Purple */
    --placeholder-color: #6b21a8; /* Dark purple */
}

[data-theme="Yellow"] {
    --background-color: #fef3c7; /* Light yellow */
    --text-color: #854d0e; /* Dark yellow */
    --modal-background: #fde68a; /* Medium light yellow */
    --sidebar-background: #fcd34d; /* Medium yellow */
    --button-background-color: #fbbf24; /* Yellow */
    --placeholder-color: #854d0e; /* Dark yellow */
}

[data-theme="Teal"] {
    --background-color: #ccfbf1; /* Light teal */
    --text-color: #064e3b; /* Dark teal */
    --modal-background: #99f6e4; /* Medium light teal */
    --sidebar-background: #5eead4; /* Medium teal */
    --button-background-color: #14b8a6; /* Teal */
    --placeholder-color: #064e3b; /* Dark teal */
}

[data-theme="Pink"] {
    --background-color: #ffe4e6; /* Light pink */
    --text-color: #9d174d; /* Dark pink */
    --modal-background: #fecdd3; /* Medium light pink */
    --sidebar-background: #fda4af; /* Medium pink */
    --button-background-color: #db2777; /* Pink */
    --placeholder-color: #9d174d; /* Dark pink */
}

/* Apply CSS Variables to Components */

.modal {
    background-color: var(--modal-background);
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar {
    background-color: var(--sidebar-background);
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Header Styling */
.header {
    background-color: var(--sidebar-background);
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Ensure Tailwind Utilities Use CSS Variables */

.btn-primary {
    @apply bg-blue-500 text-white;
    background-color: var(--button-background-color, #3b82f6);
}

.btn-secondary {
    @apply bg-gray-500 text-white;
    background-color: var(--button-background-color, #6b7280);
}

/* Smooth transitions for background and text colors */
body,
.modal,
.sidebar,
.header,
footer,
button,
a {
    transition: background-color 0.3s ease, color 0.3s ease;
}
