/* ShoppingList.css */

/* Import Google Fonts for a modern look */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Root Variables for Consistent Styling */
:root {
  --primary-color: #4A90E2; /* Blue */
  --secondary-color: #50E3C2; /* Teal */
  --danger-color: #E94E77; /* Red */
  --background-color: #F5F7FA; /* Light Gray */
  --text-color: #333333; /* Dark Gray */
  --border-color: #DDDDDD; /* Light Border */
  --input-background: #FFFFFF; /* White */
  --input-border: #CCCCCC; /* Light Gray Border */
  --button-hover: #357ABD; /* Darker Blue */
  --button-active: #2A5F8A; /* Even Darker Blue */
  --table-header-bg: #F0F4F8; /* Light Blue */
  --card-bg: #FFFFFF; /* Card Background */
  --card-border: #DDDDDD; /* Card Border */
  --card-shadow: rgba(0, 0, 0, 0.1); /* Card Shadow */
}

/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

/* Container to Center the Page Content */
.shopping-list-container {
  max-width: 1200px; /* Maximum width for large screens */
  margin: 0 auto; /* Center horizontally */
  padding: 20px; /* Inner padding */
}

/* Header Styles */
h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: var(--primary-color);
}

/* Create List Section */
.create-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  gap: 10px;
}

.create-list input {
  flex-grow: 1;
  padding: 10px 15px;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  font-size: 1rem;
  background-color: var(--input-background);
  transition: border-color 0.3s ease;
}

.create-list input:focus {
  border-color: var(--primary-color);
  outline: none;
}

.create-list button {
  padding: 10px 15px;
  background-color: var(--secondary-color);
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.create-list button:hover {
  background-color: var(--button-hover);
}

.create-list button:active {
  background-color: var(--button-active);
}

/* Shopping List Card */
.shopping-list {
  background-color: var(--card-bg);
  border: 1px solid var(--card-border);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 8px var(--card-shadow);
}

/* List Header */
.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.list-header h2 {
  font-size: 1.5rem;
  color: var(--primary-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.list-header h2 svg {
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.list-header h2.collapsed svg {
  transform: rotate(-90deg);
}

.delete-list-button {
  background: none;
  border: none;
  color: var(--danger-color);
  cursor: pointer;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.delete-list-button:hover {
  color: #C0392B; /* Darker Red */
}

/* List Content */
.list-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: auto; /* Enable horizontal scroll */
}

/* Budget Section */
.budget-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.budget-section label {
  font-weight: 500;
}

.budget-section input {
  width: 150px;
  padding: 8px 12px;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  font-size: 1rem;
  background-color: var(--input-background);
  transition: border-color 0.3s ease;
}

.budget-section input:focus {
  border-color: var(--primary-color);
  outline: none;
}

/* Search Filter */
.search-filter {
  position: relative;
}

.search-filter input {
  width: 100%;
  padding: 10px 40px 10px 15px;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  font-size: 1rem;
  background-color: var(--input-background);
  transition: border-color 0.3s ease;
}

.search-filter input:focus {
  border-color: var(--primary-color);
  outline: none;
}

.search-filter .search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999999;
  pointer-events: none;
}

/* Items Table */
.items-table {
  width: 100%;
  min-width: 900px; /* Make table wider to allow scrolling */
  border-collapse: collapse;
  table-layout: fixed; /* Ensures fixed column widths */
}

.items-table th,
.items-table td {
  padding: 12px 15px;
  border: 1px solid var(--border-color);
  text-align: center;
  word-wrap: break-word; /* Allow text to wrap */
  word-break: break-word;
}

.items-table th {
  background-color: var(--table-header-bg);
  font-weight: 500;
  font-size: 1rem;
}

.items-table td {
  font-size: 1rem;
}

/* Specific Column Adjustments */
.items-table th:nth-child(1),
.items-table td:nth-child(1) {
  width: 5ch; /* Bought Checkbox */
}

.items-table th:nth-child(2),
.items-table td:nth-child(2) {
  width: 20ch; /* Item Name - Approximately 20 characters */
}

.items-table th:nth-child(3),
.items-table td:nth-child(3) {
  width: 7ch; /* Quantity */
}

.items-table th:nth-child(4),
.items-table td:nth-child(4) {
  width: 10ch; /* Price ($) */
}

.items-table th:nth-child(5),
.items-table td:nth-child(5) {
  width: 10ch; /* Total ($) */
}

.items-table th:nth-child(6),
.items-table td:nth-child(6) {
  width: 5ch; /* Actions (Delete Button) */
}

/* Input Fields within Table */
.items-table td input[type="text"],
.items-table td input[type="number"] {
  width: 100%; /* Make inputs take full width of the cell */
  padding: 10px;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  font-size: 1rem;
  background-color: var(--input-background);
  transition: border-color 0.3s ease;
}

.items-table td input[type="text"]:focus,
.items-table td input[type="number"]:focus {
  border-color: var(--primary-color);
  outline: none;
}

/* Specific Adjustment for Name Column Input */
.items-table td:nth-child(2) input[type="text"] {
  max-width: 100%; /* Ensure it doesn't exceed the cell width */
}

/* Add Item Button */
.add-item-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: var(--secondary-color);
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.add-item-button:hover {
  background-color: #3AA59D; /* Darker Teal */
}

.add-item-button svg {
  font-size: 1rem;
}

/* List Total */
.list-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 1.1rem;
  font-weight: 500;
}

.list-total strong {
  color: var(--primary-color);
}

.list-total span {
  color: var(--text-color);
}

.list-total .over-budget {
  color: var(--danger-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  /* Container adjustments */
  .shopping-list-container {
    padding: 10px;
  }

  /* Header Section */
  .shopping-list .list-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .shopping-list .list-header h2 {
    margin-bottom: 8px;
    font-size: 1.3rem;
  }

  .shopping-list .delete-list-button {
    align-self: flex-end;
  }

  /* Create List Section */
  .shopping-list .create-list {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .shopping-list .create-list input {
    margin-right: 0;
    width: 100%;
  }

  .shopping-list .create-list button {
    width: 100%;
  }

  /* Budget Section */
  .shopping-list .budget-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .budget-section input {
    width: 100%;
  }

  /* Search Filter */
  .shopping-list .search-filter {
    display: flex;
    flex-direction: column;
  }

  .shopping-list .search-filter input {
    margin-bottom: 10px;
    width: 100%;
  }

  .shopping-list .search-filter .search-icon {
    position: static;
    transform: none;
    margin-top: -32px; /* Adjust based on input padding */
    margin-left: 5px;
  }

  /* Items Table */
  .items-table {
    min-width: 1000px; /* Make it wider to allow scrolling */
  }

  .items-table th,
  .items-table td {
    padding: 10px;
    font-size: 1rem;
  }

  /* Ellipsis for long item names */
  .items-table td:nth-child(2) {
    max-width: 20ch; /* Approximately 20 characters */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* Adjust input fields and buttons */
  .items-table td input[type="text"],
  .items-table td input[type="number"] {
    font-size: 1rem;
    padding: 12px;
  }

  .items-table button {
    font-size: 1rem;
  }

  /* Add Item Button */
  .add-item-button {
    padding: 6px 12px;
    font-size: 0.9rem;
  }

  /* List Total */
  .shopping-list .list-total {
    flex-direction: column;
    align-items: flex-start;
  }

  .shopping-list .list-total span {
    margin-top: 6px;
    font-size: 1rem;
  }
}

/* Loading and Error Messages */
.loading-message,
.error-message {
  text-align: center;
  font-size: 1.2rem;
  color: var(--text-color);
  margin-top: 50px;
}

/* Optional: Scrollbar Styling for Webkit Browsers */
.shopping-list .list-content::-webkit-scrollbar {
  height: 8px;
}

.shopping-list .list-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.shopping-list .list-content::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}

.shopping-list .list-content::-webkit-scrollbar-thumb:hover {
  background: #357ABD;
}
